import React from "react";

import { useRouter } from "next/router";
import styles from "./LangSwitchBlock.module.scss";

const LangSwitchBlock = () => {
  const router = useRouter();
  const { locales, locale } = router;

  const langName = {
    fr: "Français",
    en: "English",
    de: "Deutsch",
    it: "Italiano",
    es: "Español",
    pt: "Português",
    ru: "Русский",
    zh: "中文",
  };

  return (
    <div className="grid grid-cols-1 gap-2">
      {locales.map((loc, locIndex) => (
        <a
          href={`${loc === "fr" ? "/" : "/" + loc}`}
          value={loc}
          key={`${loc}-${locIndex}`}
          className="inline-block max-w-fit bg-transparent cursor-pointer hover:underline"
        >
          {langName[loc]}
        </a>
      ))}
    </div>
  );
};

export default LangSwitchBlock;
