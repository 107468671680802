import React from "react";
import Image from "next/image";
import * as styles from "./CardCalendar.module.scss";

import ImageCalendarCard from "@/public/card-img.png";
import IconPin from "@/public/icons/pin.svg";
import Text from "../../Core/Text";
import Icon from "@/utils/Icon";
import { slugify, upperCaseFirstLetter } from "@/utils/format";
import { useRouter } from "next/router";
import Link from "next/link";
import PlaceHolderImg from "@/public/card-placeholder.webp";
import useTranslation from "next-translate/useTranslation";
import CustomLink from "../../CustomLink";
import { DEFAULT_LOCALE } from "@/utils/global";
export default function CardCalendar({ data, className, display = "small" }) {
  const { locale } = useRouter();
  const { t } = useTranslation();

  let cardLink = null;

  if (data) {
    cardLink =
      "/" +
      (locale !== DEFAULT_LOCALE ? locale + "/" : "") +
      "a/" +
      slugify(data.title) +
      "-" +
      data.id;
  }
  const parseDate = (startDate, endDate) => {
    const languageCode = locale || "en";
    const formatStrings = {
      en: "MMMM d, yyyy",
      fr: "d MMMM yyyy",
      de: "d. MMMM yyyy",
      pt: "d de MMMM de yyyy",
      it: "d MMMM yyyy",
      ru: "d MMMM yyyy 'г.'",
      zh: "yyyy年M月d日",
    };

    const parseSingleDate = (date) => {
      const dateObj = new Date(date);

      const day = dateObj.getDate();
      const textDay = dateObj
        .toLocaleDateString("en", {
          weekday: "long",
        })
        .toLowerCase();

      const month = dateObj.toLocaleDateString(languageCode, { month: "long" });
      const year = dateObj.getFullYear();

      return { day, month, textDay, year };
    };

    const parsedStartDate = parseSingleDate(startDate);
    const parsedEndDate = parseSingleDate(endDate);
    return { startDate: parsedStartDate, endDate: parsedEndDate };
  };

  const { startDate, endDate } = parseDate(data.startDate, data.endDate);

  const getTextOpenType = (ouvertureItem) => {
    let textOpenType = "";
    let periodOpenType = ouvertureItem?.type;
    if (ouvertureItem?.dateDebut == ouvertureItem?.dateFin) {
      return;
    }
    let openDay = [];
    switch (periodOpenType) {
      case "OUVERTURE_TOUS_LES_JOURS":
        textOpenType = t("calendar:every-day");
        break;
      case "OUVERTURE_SAUF":
        if (!!ouvertureItem && ouvertureItem?.ouverturesJournalieres?.length) {
          openDay = ouvertureItem.ouverturesJournalieres?.map((entry) =>
            entry.jour?.toLowerCase()
          );
          textOpenType = t("calendar:open-except") + " " + openDay?.join(", ");
        } else {
          textOpenType = t("calendar:every-day");
        }
        break;
      case "OUVERTURE_SEMAINE":
        openDay = ouvertureItem.ouverturesJournalieres?.map((entry) =>
          entry.jour.toLowerCase()
        );
        textOpenType = `${t("calendar:every")} ${openDay?.join(", ")}`;
        break;
      case "OUVERTURE_MOIS":
        openDay = ouvertureItem.ouverturesJourDuMois?.map((entry) =>
          entry.jour.toLowerCase()
        );
        textOpenType = `${t("calendar:every")}` + openDay?.join(", ");
        break;
      default:
        break;
    }
    return textOpenType;
  };

  const localeDateString = (time) => {
    if (!data.startDate) return;

    const languageCode = locale || "en";
    const dateObj = new Date(data.startDate);

    const timeString = time || "00:00";
    const [hours, minutes] = timeString.split(":");
    dateObj.setHours(hours);
    dateObj.setMinutes(minutes);

    return dateObj.toLocaleTimeString(languageCode, {
      hour: "numeric",
      minute: "numeric",
    });
  };

  return (
    <CustomLink
      link={cardLink}
      className={`${styles.linkStyle} group ${
        display === "small" ? "col-span-3" : "col-span-4"
      }`}
    >
      <div
        className={`${className} ${styles.cardCalendar} h-full lg:hover:bg-text-h1 pt-4 pb-4 lg:pt-14 lg:pl-3 lg:pr-3 border-t lg:border-r border-filet cursor-pointer flex flex-col`}
      >
        {/* <Link href={cardLink}> */}
        <div className="flex flex-row mb-12 lg:flex-col-reverse gap-3 lg:gap-4">
          <Text as="h2" className="lg:group-hover:text-white leading-none">
            {endDate.day && endDate.day !== startDate.day ? (
              <>
                {` ${t("calendar:date-range-from-label")} `}
                {startDate.day}
                {` ${t("calendar:date-range-to-label")} `}
                {endDate.day}
              </>
            ) : (
              `${startDate.day}`
            )}
          </Text>
          <Text as="body3" className="lg:group-hover:text-white leading-none">
            {t(`calendar:day-${startDate.textDay.toLowerCase()}`)}
            {endDate.day &&
              endDate.day != startDate.day &&
              `—${t(`calendar:day-${endDate.textDay.toLowerCase()}`)}`}
          </Text>
          <div className="flex justify-between items-center">
            <Text
              as="body2"
              className="text-text-h4 capitalize lg:group-hover:text-gray-400"
            >
              {`${startDate.month}${
                startDate.year != endDate.year ? `, ${endDate.year}` : ""
              }${
                startDate.month !== endDate.month ? ` – ${endDate.month}` : ""
              }, ${endDate.year}`}
            </Text>
            {data.startDate != data.endDate &&
              data?.ouverture?.periodesOuvertures && (
                <Text
                  as="ui2"
                  className="lg:group-hover:text-black
                lg:leading-none bg-black rounded-full lg:group-hover:bg-white
                text-xs text-white text-center typeOuverture uppercase"
                >
                  {getTextOpenType(data?.ouverture?.periodesOuvertures[0])}
                </Text>
              )}
          </div>
        </div>
        <div className="flex flex-col h-full justify-end">
          <Text as="body2" className="text-text-h4 mb-2 lg:mb-4">
            {data.tag}
          </Text>
          <Text
            as="h5"
            className="lg:group-hover:text-white mb-2 lg:mb-4 text-text-h1 text-2xl"
          >
            {data.title}
          </Text>
          <div className="flex flex-row items-center gap-2 mb-4 lg:mb-6 ">
            <Icon
              icon="icon-pin-16"
              size={16}
              className="group-hover:text-white"
            />
            <Text
              as="body2"
              className="lg:group-hover:text-white text-blueBanner"
            >
              {data.location}
            </Text>
            <Text
              as="body2"
              className="lg:group-hover:text-white text-text-h4 leading-6 lg:leading-none "
            >
              {t("calendar:at")}{" "}
              {localeDateString(data?.horaireOuverture?.substring(0, 5))}
            </Text>
          </div>
          <div
            className={`${styles.imageContainer} relative w-full bg-gray-100 rounded-lg overflow-hidden`}
          >
            <Image
              className={`${styles.ratioImage} objet-cover`}
              src={data.image ? data.image : PlaceHolderImg}
              alt={"missing alt"}
              fill
              sizes="(max-width: 768px) 80vw, (max-width: 1024px) 50vw, 33vw"
            />
            {data.pin && (
              <div
                className={`${styles.overlay} absolute flex flex-col justify-center items-center bottom-2 left-2 pt-2 pb-2 pl-3 pr-3 text-xs uppercase rounded-3xl bg-blueBanner text-white font-bold tracking-wide`}
              >
                {data.pin}
              </div>
            )}
          </div>
        </div>
      </div>
    </CustomLink>
  );
}
