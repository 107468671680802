import { upperCaseFirstLetter } from "@/utils/format"
import { useRouter } from "next/router"
import styles from "./LangSwitcher.module.scss"

const LangSwitcher = () => {
  const router = useRouter();
  const { locales, locale } = router

  const changeLocale = (e) => {
    const loc = e.target.value;

    if (loc === 'fr') {
      router.push(`/fr${router.asPath}`, `/fr${router.asPath}`, { locale: loc });
    } else {
      router.push(router.asPath, router.asPath, { locale: loc });
    }

    router.events.on('routeChangeComplete', () => {
      router.reload();
    });
  };

  return <select
    onChange={changeLocale}
    className={`${styles.langSwitcher} bg-transparent cursor-pointer hover:underline`}
    value={locale}
  >
    {locales.filter((loc) => loc !== 'default').map((loc, locIndex) => (
      <option value={loc} key={`${loc}-${locIndex}`}>
        {upperCaseFirstLetter(loc)}
      </option>
    ))}
  </select>
}

export default LangSwitcher