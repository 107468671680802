import Head from "next/head";
import useTranslation from "next-translate/useTranslation";
import * as styles from "./DrawerPage.module.scss";
import { StoryblokComponent } from "@storyblok/react";
import WeatherModule from "./WeatherModule";
import WebcamModule from "./WebcamModule";
import TrailModule from "./TrailModule";
import dynamic from "next/dynamic";

const DynamicFollowBlock = dynamic(
  () => import("@/common/components/FollowBlock"),
  {
    loading: () => <p>Loading...</p>,
  }
);

export default function DrawerPage({ blok, settings, nestedData, drawerData, params }) {
  // console.log("blok-drawer", blok)
  const { t } = useTranslation("common");

  let title =
    blok && blok.title !== ""
      ? blok.title + ` - ${t("courchevel")}`
      : t("courchevel");
  let description =
    blok && blok.title !== ""
      ? blok.title + ` - ${t("courchevel")}`
      : t("courchevel");
  let robots = "";
  if (blok?.meta_title) {
    title = blok.meta_title;
  }
  if (blok?.meta_description) {
    description = blok.meta_description;
  }
  if (blok?.hide_from_search) {
    robots = blok.hide_from_search ? "noindex, nofollow" : "index, follow";
  }
  const faq = blok?.faq && blok.faq[0];

  return (
    <div className={styles.drawerPage}>
      <Head>
        <title>{title}</title>
        <meta name="description" content={description} />
        {robots && <meta name="robots" content={robots} />}
      </Head>

      <div className={`${styles.drawerPageContent} content-blocks relative bg-white z-10`}>
        {blok?.type_drawer === "weather"
          ? <WeatherModule blok={blok} drawerData={drawerData} />
          : blok?.type_drawer === "webcam" ?
            <WebcamModule blok={blok} drawerData={drawerData} />
            : blok?.type_drawer === "trail" ?
              <TrailModule blok={blok} drawerData={drawerData} /> : undefined}

        {blok?.body?.map((nestedBlok) => {
          if (nestedBlok?.is_hidden) return null;
          if (nestedBlok?.component === "push_section") {
            return <div className="container" key={nestedBlok._uid}>
              <StoryblokComponent
                blok={nestedBlok}
                nestedData={nestedData}
              />
            </div>
          }
          return (
            <StoryblokComponent
              blok={nestedBlok}
              key={nestedBlok._uid}
              nestedData={nestedData}
            />
          )
        })}
      </div>

      {blok.cross_content &&
        blok.cross_content.map((crossContentBlock) => (
          <StoryblokComponent
            blok={crossContentBlock}
            key={`ccbi-${crossContentBlock._uid}`}
            nestedData={nestedData}
          />
        ))}

      <DynamicFollowBlock />

      {faq && <StoryblokComponent blok={faq} />}
    </div>
  );
}
