import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import Head from "next/head";
import * as styles from "./HomeBlogPage.module.scss";
import * as stylesStory from "@/common/components/Story/Story.module.scss";
import * as stylesHero from "@/common/components/Hero/Hero.module.scss";
import Hero from "@/common/components/Hero";
import Text from "../../../Core/Text";
import CardBlog from "@/common/components/Card/Blog";
import React, { useState } from "react";
import BlogNav from "../../../BlogNav";
import InfoBar from "../../InfoBar";
import useTranslation from "next-translate/useTranslation";
export default function HomeBlogPage({
  blok,
  settings,
  nestedData,
  dataCateg,
  dataArticles,
  highlightedArticles,
  dataConfig,
}) {
  const { t } = useTranslation("common");

  let title =
    blok.hero && blok.hero[1]
      ? blok.hero[1]?.title + ` - ${t("courchevel")}`
      : blok.hero && blok.hero[0]
        ? blok.hero[0].title + ` - ${t("courchevel")}`
        : t("courchevel");
  let description =
    blok.hero && blok.hero[1]
      ? blok.hero[1]?.title + ` - ${t("courchevel")}`
      : blok.hero && blok.hero[0]
        ? blok.hero[0].title + ` - ${t("courchevel")}`
        : t("courchevel");

  let robots = "";
  if (dataConfig?.meta_title) {
    title = dataConfig.meta_title;
  }
  if (dataConfig?.meta_description) {
    description = dataConfig.meta_description;
  }
  if (dataConfig?.hide_from_search) {
    robots = dataConfig.hide_from_search
      ? "noindex, nofollow"
      : "index, follow";
  }
  const categoryDictionary = {};
  dataCateg.forEach((category) => {
    categoryDictionary[category.uuid] = category.label;
  });

  dataArticles.forEach((article) => {
    const categoryUUIDs = article.category;
    const categoryLabels = categoryUUIDs.map(
      (uuid) => categoryDictionary[uuid]
    );
    article.categoryLabels = categoryLabels;
  });

  highlightedArticles.forEach((article) => {
    const categoryUUIDs = article.category;
    const categoryLabels = categoryUUIDs.map(
      (uuid) => categoryDictionary[uuid]
    );
    article.categoryLabels = categoryLabels;
  });

  const [selectedCategory, setSelectedCategory] = useState("");

  function filterContent(content, category) {
    const filtered = content.filter((item) => {
      const filteredCategory = category === item.slug;
      return filteredCategory;
    });
    if (filtered.length === 0) {
      return content;
    } else {
      return filtered;
    }
  }
  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };
  function getAllCategories(articles) {
    const categoriesMap = new Map();

    articles.forEach((article) => {
      const { category, slug } = article;
      categoriesMap.set(slug, { label: category, slug });
    });

    const categories = Array.from(categoriesMap.values());

    return categories;
  }

  //console.log(allCategories);
  return (
    <div className={styles.homeBlogPage} {...storyblokEditable(blok)}>
      <Head>
        <title>{title}</title>
        <meta name="description" content={description} />
        {robots && <meta name="robots" content={robots} />}
      </Head>
      <Hero content={blok.hero} {...storyblokEditable(blok.hero)} settings={settings}/>
      <div className={stylesHero.heroPlaceholder}></div>
      {settings?.content?.infobar && settings?.content?.infobar[0] && (
        <InfoBar blok={settings.content.infobar[0]} />
      )}
      <div className="relative bg-white z-10">
        <div className="container">
          <div className="pt-14">
            {blok?.Highlighted && (
              <Text as="h3" tag="h2" className="mb-5 text-h2">
                {blok?.Highlighted[0]?.title}
              </Text>
            )}
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-3">
              {highlightedArticles &&
                highlightedArticles.length &&
                highlightedArticles.map((item, index) => (
                  <CardBlog
                    key={`${index}-card`}
                    data={item}
                    highlight="true"
                    className="lg:col-span-4"
                  />
                ))}
            </div>
          </div>
          <div className="mt-10 lg:mt-14 pb-14">
            <BlogNav
              categories={dataCateg}
              first="Tout voir"
              onCategoryChange={handleCategoryChange}
            />
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  gap-x-3 gap-y-6 lg:gap-y-20">
              {dataArticles &&
                dataArticles.length &&
                dataArticles
                  .slice(0, 9)
                  .map((item, index) => (
                    <CardBlog
                      key={`${index}-cardlist`}
                      data={item}
                      className="lg:col-span-4"
                    />
                  ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
