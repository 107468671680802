import {
  StoryblokComponent,
  storyblokEditable,
  useStoryblokState,
} from "@storyblok/react";
import Head from "next/head";
import dynamic from "next/dynamic";
import * as styles from "./ListingPage.module.scss";
import * as stylesHero from "@/common/components/Hero/Hero.module.scss";
import Hero from "@/common/components/Hero";
import PushBlock from "../../PushBlock";
import CardActivity from "@/common/components/Card/Activity";
import useTranslation from "next-translate/useTranslation";
import InfoBar from "../../InfoBar";
import BlogContent from "@/common/components/Story/BlogContent";

const DynamicFollowBlock = dynamic(
  () => import("@/common/components/FollowBlock"),
  {
    loading: () => <p>Loading...</p>,
  }
);

export default function ListingPage(props) {
  const { blok, settings, nestedData, dataConfig } = props || {};

  console.log("listing page blok", blok);
  const { t } = useTranslation("common");

  let title =
    blok.hero && blok.hero[1]
      ? blok.hero[1]?.title + ` - ${t("courchevel")}`
      : blok.hero && blok.hero[0]
      ? blok.hero[0].title + ` - ${t("courchevel")}`
      : t("courchevel");

  let description =
    blok.hero && blok.hero[1]
      ? blok.hero[1]?.title + ` - ${t("courchevel")}`
      : blok.hero && blok.hero[0]
      ? blok.hero[0].title + ` - ${t("courchevel")}`
      : t("courchevel");
  let robots = "";
  if (blok?.meta_title) {
    title = blok.meta_title;
  }
  if (blok?.meta_description) {
    description = blok.meta_description;
  }
  if (blok?.hide_from_search) {
    robots = blok.hide_from_search ? "noindex, nofollow" : "index, follow";
  }

  const introductionBlok = blok.content?.find(
    (blok) => blok.component === "introduction"
  );
  const agendaBlok = blok.content?.find(
    (blok) => blok.component === "agenda_block"
  );
  let contentFiltered = blok.content?.filter(
    (blok) => blok.component !== "introduction"
  );
  contentFiltered = contentFiltered?.filter(
    (blok) => blok.component !== "agenda_block"
  );

  const childrenInNestedData = [];
  if (
    nestedData &&
    nestedData.length > 0 &&
    blok.child &&
    blok.child.length > 0
  ) {
    // Loop over the blok.child array (containing uuid strings) and find the corresponding nestedData object
    blok.child.forEach((blokChild) => {
      const child = nestedData.find(
        (nestedDataItem) => nestedDataItem.uuid === blokChild
      );
      if (child) {
        childrenInNestedData.push(child);
      }
    });

    childrenInNestedData.sort((a, b) => {
      const nameA = (
        a.content?.hero?.length > 0 ? a.content?.hero[0]?.title : a.name
      ).toLowerCase();
      const nameB = (
        b.content?.hero?.length > 0 ? b.content?.hero[0]?.title : b.name
      ).toLowerCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  }

  const faq = blok.faq && blok.faq[0];

  return (
    <div className={styles.listingPage} {...storyblokEditable(blok)}>
      <Head>
        <title>{title}</title>
        <meta name="description" content={description} />
        {robots && <meta name="robots" content={robots} />}
      </Head>
      <Hero content={blok.hero} settings={settings} />
      <div className={stylesHero.heroPlaceholder}></div>
      {settings?.content?.infobar && settings.content.infobar[0] && (
        <InfoBar blok={settings.content.infobar[0]} />
      )}
      <div className="content-blocks relative bg-white z-10">
        {introductionBlok && !introductionBlok.is_hidden && (
          <StoryblokComponent
            blok={introductionBlok}
            key={introductionBlok._uid}
          />
        )}

        {childrenInNestedData && childrenInNestedData.length > 0 && (
          <div className="container">
            <div
              className={`flex flex-nowrap lg:grid grid-cols-12 gap-x-3 lg:gap-y-14 overflow-x-scroll lg:overflow-x-hidden`}
            >
              {childrenInNestedData.map((nestedBlok) => (
                <CardActivity
                  data={nestedBlok}
                  key={nestedBlok.uuid}
                  className="col-span-4"
                />
              ))}
            </div>
          </div>
        )}

        {contentFiltered &&
          contentFiltered.map((nestedBlok) => (
            <StoryblokComponent
              blok={nestedBlok}
              key={nestedBlok._uid}
              nestedData={nestedData}
            />
          ))}

        {blok.sectionPushMeteo && <PushBlock data={settings?.content} />}
        {agendaBlok && <StoryblokComponent blok={agendaBlok} />}
        <DynamicFollowBlock />
        {faq && <StoryblokComponent blok={faq} />}
        {blok.seo_content && blok.seo_content.length > 0 && (
          <div className="mt-6">
            {blok.seo_content.map((blok) => (
              <BlogContent blok={blok} key={blok._uid} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
