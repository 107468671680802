import React, { createRef, useCallback, useEffect, useState } from "react";
import Image from "next/image";
import useTranslation from "next-translate/useTranslation";
import * as styles from "./DrawerPage.module.scss";
import * as stylesDrawer from "@/common/components/Modals/Drawer.module.scss";
import Text from "@/common/components/Core/Text";
import Icon from "@/utils/Icon";
import Button from "@/common/components/Core/Button";
import { SEASON_WINTER } from "@/utils/global";
import { getLiftTypeIcon } from "@/utils/weather";
import { upperCaseFirstLetter } from "@/utils/format";
import { useDrawers } from "@/common/components/DrawerStack";
import dynamic from "next/dynamic";
import Paragraph, {
  storyblokContentRenderOptions,
} from "@/common/components/Story/Paragraph";
import { render } from "storyblok-rich-text-react-renderer";
const DynamicDrawerInteractiveMap = dynamic(
  () => import("../../../Modals/DrawerInteractiveMap"),
  {
    ssr: false,
  }
);

export const OPEN_STATUS = [
  "OPEN",
  "OPEN_PEDESTRIAN_ONLY",
  "OPEN_DOWNHILL_ONLY",
];
export const ISSUE_STATUS = [
  "DELAYED",
  "DELAYED_TECHNICAL_ISSUE",
  "DELAYED_SECURING",
  "DELAYED_WEATHER",
  "DELAYED_WIND",
];
export const CLOSED_STATUS = [
  "UNDEF",
  "FORECAST",
  "FORECAST_AURORA_SKI",
  "FORECAST_NIGHT_SKI",
  "STOPPED",
  "STOPPED_TECHNICAL_ISSUE",
  "STOPPED_SECURING",
  "STOPPED_WEATHER",
  "STOPPED_WIND",
  "CLOSED",
  "CLOSED_CONTEST",
  "OUT_OF_PERIOD",
  "OUT_OF_PERIOD_SEASON_BEGINNING",
  "OUT_OF_PERIOD_SEASON_ENDING",
];

const TrailModule = ({ blok, drawerData }) => {
  const [isLoadingSectors, setIsLoadingSectors] = useState(false);
  const [isLoadingSectorPois, setIsLoadingSectorPois] = useState(false);
  const [sampleDataSectors, setSampleDataSectors] = useState([]);
  const [sampleDataSectorsPois, setSampleDataSectorsPois] = useState(new Map());
  const [selectedSector, setSelectedSector] = useState(null);
  const [selectedList, setSelectedList] = useState(0);
  const [mapFileLink, setMapFileLink] = useState(null);
  const { closeDrawers, openDrawer } = useDrawers();
  const [seasonMode, setSeasonMode] = useState(SEASON_WINTER);

  const [trailId, setTrailId] = useState(null);

  const { t } = useTranslation("common");

  useEffect(
    () => {
      const fetchTrailData = async () => {
        const data = drawerData?.data;
        let sectors = [];

        if (data?.content?.resorts && data?.content?.resorts[0]) {
          sectors = data?.content?.resorts[0].sectors;
        }

        if (sectors && sectors.length > 0) {
          setSampleDataSectors(sectors || []);

          if (sectors[0]) {
            await onChangeSector(sectors[0].id);
          }
        }
      };

      let seasonMode = SEASON_WINTER;
      if (typeof window !== "undefined") {
        seasonMode = localStorage.getItem("seasonMode");
      }
      setSeasonMode(seasonMode);

      // Call API getWeatherForecast here and set data to state
      fetchTrailData();

      if (blok?.plan?.filename) {
        setMapFileLink(blok.plan.filename);
      } else {
        setMapFileLink(null);
      }
    },
    [drawerData],
    [blok?.plan?.filename]
  );

  const fetchSectorPois = useCallback(async (sectorId) => {
    try {
      setIsLoadingSectorPois(true);

      const response = await fetch(
        `/api/lumiplan/get-sector-pois?sectorId=${sectorId}&operating=all`
      );
      if (response.ok) {
        const data = await response.json();
        if (data?.content?.sectors && data?.content?.sectors[0]) {
          setIsLoadingSectorPois(false);
          return data?.content?.sectors[0];
        }
      }
    } catch (e) {
      console.error("Error TrailModule - fetchSectorPois", e);
    }

    setIsLoadingSectorPois(false);
  }, []);

  const onChangeSector = useCallback(
    async (sectorId) => {
      setSelectedSector(sectorId);

      setTrailId(null);
      if (sampleDataSectorsPois.get(sectorId)) {
        //console.log("Data already fetched");
        setIsLoadingSectorPois(false);
      } else {
        // TODO: Call API getResortSectorPois here and add data to the sampleDataSectorsPois state
        const sectorPois = await fetchSectorPois(sectorId);
        setSampleDataSectorsPois(
          new Map(
            sampleDataSectorsPois.set(sectorId, {
              lifts: sectorPois?.lifts || [],
              trails: sectorPois?.trails || [],
            })
          )
        );
      }

      setSelectedList(0);
    },
    [fetchSectorPois, sampleDataSectorsPois]
  );

  const displayRichText = (id) => {
    setTrailId(id);
    setSelectedSector(null);
  };

  const onChangeList = useCallback((index) => {
    setSelectedList(index);
  }, []);

  const getColorClassByLevel = (level) => {
    switch (level) {
      case "GREEN":
        return "text-track-green";
      case "BLUE":
        return "text-track-blue";
      case "RED":
        return "text-track-red";
      case "BLACK":
        return "text-track-black";
      default:
        return "text-text-h1";
    }
  };

  const getOpeningStatus = (openingStatus) => {
    if (OPEN_STATUS.includes(openingStatus)) {
      return "OPEN";
    } else if (ISSUE_STATUS.includes(openingStatus)) {
      return "ISSUE";
    } else if (CLOSED_STATUS.includes(openingStatus)) {
      return "CLOSED";
    } else {
      return "CLOSED";
    }
  };

  const onOpenInteractiveMap = () => {
    openDrawer(
      DynamicDrawerInteractiveMap,
      {
        key: "interactive-map",
      },
      { nodeRef: createRef(null) }
    );
  };

  const currentSectorPois = sampleDataSectorsPois.get(selectedSector);
  // Inside currentSectionPois, we have lifts and trails
  // Inside trails, we have trailLevel (BLUE, RED, BLACK, GREEN)
  // Loop through trails and create a Map structure grouping them by trailLevel
  const trailLevels = new Map();
  if (
    currentSectorPois &&
    currentSectorPois.trails &&
    currentSectorPois.trails.length > 0
  ) {
    currentSectorPois.trails.forEach((trail) => {
      if (trailLevels.has(trail.trailLevel)) {
        trailLevels.get(trail.trailLevel).push(trail);
      } else {
        trailLevels.set(trail.trailLevel, [trail]);
      }
    });
  }
  const greenTrails = trailLevels.get("GREEN");
  const blueTrails = trailLevels.get("BLUE");
  const redTrails = trailLevels.get("RED");
  const blackTrails = trailLevels.get("BLACK");

  const images = blok?.fond?.length > 0 ? blok?.fond : [];
  const image =
    seasonMode === SEASON_WINTER
      ? images[0]
      : images.length > 1
        ? images[1]
        : images[0];

  // clean sampleDataSectors, if they do not have trails or lifts remove them

  return (
    <div className={`${styles.lumiplanModule} ${styles.trailModule}`}>
      <div className={`${styles.drawerBg} absolute top-0 inset-0`}>
        {blok && (
          <Image
            src={image?.filename || "/meteo-desktop-bg.png"}
            alt={image?.alt || "missing alt"}
            fill
            sizes="(max-width: 768px) 80vw, (max-width: 1024px) 70vw, 50vw"
            className="object-cover"
          />
        )}
      </div>

      <div className="container relative">
        <Text as="h1" tag="h1" className="">
          {blok?.title}
        </Text>

        <div className="mt-4 lg:mt-16 lg:grid grid-cols-12 gap-x-3 w-full lg:overflow-auto">
          <div className="lg:col-span-4 self-start lg:sticky top-0 lg:pb-10">
            {blok?.subtitle && (
              <Text as="ui2" className="uppercase mb-6 lg:mb-10">
                {blok?.subtitle}
              </Text>
            )}
            <div
              className={`${stylesDrawer.listItemsContainer} flex whitespace-nowrap overflow-auto mobile-overflow-container lg:whitespace-normal lg:overflow-hidden gap-x-3 lg:mb-4 lg:flex-col lg:gap-y-2 items-center lg:items-start`}
            >
              {sampleDataSectors.map((sector, index) => (
                <React.Fragment key={`dt${index}`}>
                  {(sector.trails && sector.trails.length > 0) ||
                    (sector.lifts && sector.lifts.length > 0) ? (
                    <button
                      onClick={() => onChangeSector(sector.id)}
                      className={`${selectedSector === sector.id ? "" : "opacity-50"
                        } hover:opacity-100`}
                    >
                      <Text as="h4">{upperCaseFirstLetter(sector.name)}</Text>
                    </button>
                  ) : null}
                </React.Fragment>
              ))}

              {blok.trail_text &&
                blok.trail_text.map((trail, index) => (
                  <button
                    onClick={() => displayRichText(trail.id)}
                    key={`dt${index}`}
                    className={`${trailId === trail.id ? "" : "opacity-50"
                      } hover:opacity-100`}
                  >
                    <Text as="h4">{upperCaseFirstLetter(trail.titre)}</Text>
                  </button>
                ))}
            </div>

            <div className="mt-4 lg:mt-20 hidden lg:flex flex-col lg:flex-row gap-x-5">
              <Button
                className="w-full mt-4 md:w-auto"
                onClick={onOpenInteractiveMap}
              >
                {t("trail.drawer.cta-interactive-map")}
              </Button>
              {mapFileLink && (
                <Button
                  tag="link"
                  link={{ url: mapFileLink }}
                  linktype="url"
                  className="w-full mt-4 md:w-auto"
                  rel="noopener noreferrer"
                  download
                >
                  {t("trail.drawer.cta-download-map")}
                </Button>
              )}
            </div>
            {blok.trail_cta && blok.trail_cta.length > 0 && (
              <div className="mt-4 lg:mt-4 hidden lg:flex flex-col lg:flex-row gap-x-5">
                {blok.trail_cta.map((cta, index) => (
                  <Button
                    key={`cta${index}`}
                    className="w-full  md:w-auto"
                    tag="a"
                    link={cta.link}
                    linktype="url"
                    rel="noopener noreferrer"
                  >
                    {cta.label}
                  </Button>
                ))}
              </div>
            )}
          </div>
          <div
            className={`${stylesDrawer.poisContainer} ${isLoadingSectors || isLoadingSectorPois ? "cursor-wait" : ""
              } overflow-auto lg:overflow-initial pb-6 lg:pb-0 lg:col-span-8 lg:grid-cols-8 text-text-h1 rounded-xl`}
          >
            <div
              className={`${stylesDrawer.poisContent}  bg-white lg:grid lg:grid-cols-8 gap-x-3`}
            >
              {!trailId && (
                <div className="lg:col-start-2 lg:col-end-8">
                  <div
                    className={`${stylesDrawer.poisHead} py-8 px-4 lg:pt-16 lg:px-0`}
                  >
                    <Text as="ui2" className="uppercase">
                      {t("trail.drawer.trails-open-title")}
                    </Text>

                    <div className="flex flex-wrap rounded-xl bg-background-beige gap-y-6 py-4 px-6 mt-4">
                      {currentSectorPois && (
                        <>
                          {greenTrails && (
                            <div className="w-1/2">
                              <div className="flex ">
                                <p className={`${stylesDrawer.mainNumber}`}>
                                  {
                                    greenTrails.filter(
                                      (trail) =>
                                        getOpeningStatus(
                                          trail.openingStatus
                                        ) === "OPEN"
                                    ).length
                                  }
                                </p>
                                <Text as="ui1" className="text-text-h4">
                                  /{greenTrails.length}
                                </Text>
                              </div>
                              <Text as="ui1" className="mt-1 text-text-h4">
                                {t("trail.drawer.trail-green")}
                              </Text>
                            </div>
                          )}
                          {blueTrails && (
                            <div className="w-1/2">
                              <div className="flex ">
                                <p className={`${stylesDrawer.mainNumber}`}>
                                  {
                                    blueTrails.filter(
                                      (trail) =>
                                        getOpeningStatus(
                                          trail.openingStatus
                                        ) === "OPEN"
                                    ).length
                                  }
                                </p>
                                <Text as="ui1" className="text-text-h4">
                                  /{blueTrails.length}
                                </Text>
                              </div>
                              <Text as="ui1" className="mt-1 text-text-h4">
                                {t("trail.drawer.trail-blue")}
                              </Text>
                            </div>
                          )}
                          {redTrails && (
                            <div className="w-1/2">
                              <div className="flex ">
                                <p className={`${stylesDrawer.mainNumber}`}>
                                  {
                                    redTrails.filter(
                                      (trail) =>
                                        getOpeningStatus(
                                          trail.openingStatus
                                        ) === "OPEN"
                                    ).length
                                  }
                                </p>
                                <Text as="ui1" className="text-text-h4">
                                  /{redTrails.length}
                                </Text>
                              </div>
                              <Text as="ui1" className="mt-1 text-text-h4">
                                {t("trail.drawer.trail-red")}
                              </Text>
                            </div>
                          )}
                          {blackTrails && (
                            <div className="w-1/2">
                              <div className="flex ">
                                <p className={`${stylesDrawer.mainNumber}`}>
                                  {
                                    blackTrails.filter(
                                      (trail) =>
                                        getOpeningStatus(
                                          trail.openingStatus
                                        ) === "OPEN"
                                    ).length
                                  }
                                </p>
                                <Text as="ui1" className="text-text-h4">
                                  /{blackTrails.length}
                                </Text>
                              </div>
                              <Text as="ui1" className="mt-1 text-text-h4">
                                {t("trail.drawer.trail-black")}
                              </Text>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>

                  <div className={`${stylesDrawer.poisBody} lg:mt-4`}>
                    <div className="flex text-center lg:text-left lg:gap-x-3">
                      <div
                        className={`${stylesDrawer.poisListSwitcher} relative ${selectedList === 0 ? stylesDrawer.isActive : ""
                          } w-1/2 py-3`}
                        onClick={() => onChangeList(0)}
                      >
                        <Text as="ui2" className="uppercase">
                          {t("trail.drawer.trails-title")}
                        </Text>
                      </div>
                      <div
                        className={`${stylesDrawer.poisListSwitcher} relative ${selectedList === 1 ? stylesDrawer.isActive : ""
                          } w-1/2 py-3`}
                        onClick={() => onChangeList(1)}
                      >
                        <Text as="ui2" className="uppercase">
                          {t("trail.drawer.lifts-legend")}
                        </Text>
                      </div>
                    </div>

                    <div className="flex lg:gap-x-3">
                      {currentSectorPois &&
                        currentSectorPois.trails &&
                        currentSectorPois.trails.length > 0 && (
                          <div
                            className={`${stylesDrawer.poisList} ${selectedList === 0 ? stylesDrawer.isActive : ""
                              } flex-1 pt-6 pb-8 gap-x-3 lg:pt-2`}
                          >
                            {currentSectorPois.trails.map(
                              (trail, trailIndex) => (
                                <div
                                  key={`${selectedSector}-trail-${trail.id}-${trailIndex}`}
                                  className={`${stylesDrawer.poisLine} flex items-center px-4 py-3 lg:py-4`}
                                >
                                  <Icon
                                    icon="icon-ski-24"
                                    size={24}
                                    className={`mr-4 ${getOpeningStatus(trail.openingStatus) ===
                                      "CLOSED"
                                      ? "opacity-50"
                                      : ""
                                      } ${getColorClassByLevel(
                                        trail.trailLevel
                                      )}`}
                                  />
                                  <Text
                                    as="body2"
                                    className={`${getOpeningStatus(trail.openingStatus) ===
                                      "CLOSED"
                                      ? "text-text-h4"
                                      : ""
                                      }`}
                                  >
                                    {trail.name}
                                  </Text>
                                  {getOpeningStatus(trail.openingStatus) ===
                                    "CLOSED" ? (
                                    <Icon
                                      icon="icon-cross-filled-16"
                                      size={16}
                                      className="ml-auto text-track-red"
                                    />
                                  ) : (
                                    <Icon
                                      icon="icon-check-filled-16"
                                      size={16}
                                      className={`ml-auto ${getOpeningStatus(
                                        trail.openingStatus
                                      ) === "ISSUE"
                                        ? "text-orange-500"
                                        : "text-valid"
                                        }`}
                                    />
                                  )}
                                </div>
                              )
                            )}
                          </div>
                        )}

                      {currentSectorPois &&
                        currentSectorPois.lifts &&
                        currentSectorPois.lifts.length > 0 && (
                          <div
                            className={`${stylesDrawer.poisList} ${selectedList === 1 ? stylesDrawer.isActive : ""
                              } flex-1 pt-6 pb-8 gap-x-3 lg:pt-2`}
                          >
                            {currentSectorPois.lifts.map((lift, liftIndex) => (
                              <div
                                key={`${selectedSector}-lift-${lift?.id}-${liftIndex}`}
                                className={`${stylesDrawer.poisLine} flex items-center px-4 py-3 lg:py-4`}
                              >
                                <Icon
                                  icon={`${getLiftTypeIcon(lift?.liftType)?.icon
                                    }`}
                                  size={24}
                                  className={`mr-4 ${getOpeningStatus(lift?.openingStatus) ===
                                    "CLOSED"
                                    ? "text-text-h4"
                                    : ""
                                    }`}
                                />
                                <Text
                                  as="body2"
                                  className={`${getOpeningStatus(lift?.openingStatus) ===
                                    "CLOSED"
                                    ? "text-text-h4"
                                    : ""
                                    }`}
                                >
                                  {lift?.name}
                                </Text>
                                {getOpeningStatus(lift?.openingStatus) ===
                                  "CLOSED" ? (
                                  <Icon
                                    icon="icon-cross-filled-16"
                                    size={16}
                                    className="ml-auto text-track-red"
                                  />
                                ) : (
                                  <Icon
                                    icon="icon-check-filled-16"
                                    size={16}
                                    className={`ml-auto ${getOpeningStatus(lift?.openingStatus) ===
                                      "ISSUE"
                                      ? "text-orange-500"
                                      : "text-valid"
                                      }`}
                                  />
                                )}
                              </div>
                            ))}
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              )}
              {trailId && (
                <div className="lg:col-start-2 lg:col-end-8 pb-8 pt-2 px-4">
                  {render(
                    blok.trail_text.find((trail) => trail.id === trailId)
                      ?.contenu,
                    storyblokContentRenderOptions
                  )}
                </div>
              )}
            </div>
          </div>

          <div className="flex flex-col lg:flex-row gap-x-5 lg:hidden">
            <Button
              className="w-full mt-4 md:w-auto"
              onClick={onOpenInteractiveMap}
            >
              {t("trail.drawer.cta-interactive-map")}
            </Button>
            {mapFileLink && (
              <Button
                tag="link"
                link={{ url: mapFileLink }}
                linktype="url"
                className="w-full mt-4 md:w-auto"
                rel="noopener noreferrer"
                download
              >
                {t("trail.drawer.cta-download-map")}
              </Button>
            )}
          </div>
          {blok.trail_cta.length && blok.trail_cta.length > 0 && (
            <div className="mt-4 hidden col-span-12 lg:flex flex-col lg:flex-row gap-x-5">
              {blok.trail_cta.map((cta, index) => (
                <Button
                  key={`cta${index}`}
                  className="w-full mt-4 md:w-auto"
                  tag="a"
                  link={cta.link}
                  linktype="url"
                  rel="noopener noreferrer"
                >
                  {cta.label}
                </Button>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TrailModule;
