import React, { useEffect, useState } from "react";
import * as styles from "./CardSearch.module.scss";
import * as stylesCard from "../Card.module.scss";
import Image from "next/image";
import Text from "../../Core/Text";
import ArrowLink from "../../Core/ArrowLink";
import useTranslation from "next-translate/useTranslation";
import {
  DEFAULT_LOCALE,
  SEASON_WINTER,
  getLangValue,
  getLangValueWithoutFallback,
} from "@/utils/global";
import { slugify, upperCaseFirstLetter } from "@/utils/format";
import Link from "next/link";
import { useRouter } from "next/router";
import CustomLink from "../../CustomLink";
import { disableBodyScroll, enableBodyScroll } from "@/utils/scroll";
export default function CardSearch(props) {
  const { t } = useTranslation("common");
  const { locale } = useRouter();
  const { data, className, variant } = props;
  const content = data?.content || null;
  const dataFromApidae = !!data?.identifier;

  const [imageBySeason, setImageBySeason] = useState(null);

  useEffect(() => {
    if (content) {
      let seasonMode = SEASON_WINTER;

      if (typeof window !== "undefined") {
        seasonMode = localStorage.getItem("seasonMode");
      }

      let heroImages = [];

      if (content.hero?.length > 0) {
        content.hero?.map((item) => {
          if (item.Image) {
            heroImages.push(item.Image);
          }
        });
      }

      const images =
        content.card_thumbnail?.length > 0 ? content.card_thumbnail : [];

      if (images?.length === 0) {
        const image =
          seasonMode === SEASON_WINTER
            ? heroImages[0]
            : heroImages.length > 1
              ? heroImages[1]
              : heroImages[0];
        setImageBySeason(image);
      } else {
        const image =
          seasonMode === SEASON_WINTER
            ? images[0]
            : images.length > 1
              ? images[1]
              : images[0];

        setImageBySeason(image);
      }
    }
  }, [content]);

  let cardThumbnail = null;
  let cardName = null;
  let cardDescription = null;
  let cardLink = "";
  if (dataFromApidae) {
    const langKeyTrad = `libelle${upperCaseFirstLetter(locale)}`;
    const defaultLangKeyTrad = `libelle${upperCaseFirstLetter(DEFAULT_LOCALE)}`;
    if (data.illustrations?.length) {
      let langTradIllustration = data.illustrations[0].traductionFichiers.find(
        (item) => item.locale === locale
      );
      if (!langTradIllustration) {
        langTradIllustration = data.illustrations[0].traductionFichiers.find(
          (item) => item.locale === "en"
        );
      }
      if (!langTradIllustration) {
        langTradIllustration = data.illustrations[0].traductionFichiers.find(
          (item) => {
            return item.locale === DEFAULT_LOCALE;
          }
        );
      }

      if (langTradIllustration) {
        cardThumbnail = {
          filename: langTradIllustration.url,
          alt: data.illustrations[0]?.legende
            ? getLangValueWithoutFallback(
              data.illustrations[0].legende,
              langKeyTrad
            )
            : "",
        };
      }
    }
    cardName = getLangValue(data.nom, langKeyTrad);
    cardDescription =
      data.presentation && data.presentation.descriptifCourt
        ? getLangValueWithoutFallback(
          data.presentation.descriptifCourt,
          langKeyTrad
        )
        : null;
    // cardLink = (locale !== DEFAULT_LOCALE ? locale + '/' : '') + slugify(data.type) + '/' + slugify(cardName) + '-' + data.id;
    cardLink =
      "/" +
      (locale !== DEFAULT_LOCALE ? locale + "/" : "") +
      "a/" +
      slugify(cardName) +
      "-" +
      data.id;
  } else if (content) {
    // console.log(content)
    cardThumbnail = imageBySeason;
    cardName =
      content?.hero?.length > 0 ? content?.hero[0]?.title : content.name;
    cardDescription = content.short_description;
    // if full_slug contain 'livecames' then link to '/livecams#xxx' instead of '/livecams/xxx
    if(data.full_slug && data.full_slug.includes('livecams/')){
      if (content.illustration && content.illustration.length > 0) {
        cardThumbnail = content.illustration[0];
      }
      let url = data.full_slug.split('/livecams/');
      if (url.length === 1) {
        cardLink = "/livecams/webcam?webcam=" + url[0];
      } else if (url.length > 1) {
        cardLink = url[0] + "/livecams/webcam?webcam=" + url[1];
      }
      cardName = `${data.name} - ${t("common:lumiplan.livecams.webcam")} `;
    } else {
      cardLink = data.full_slug ? `/${data.full_slug}` : "";
    }
  }

  return (
    <CustomLink
      onClick={() => {
        enableBodyScroll(document);
      }}
      link={cardLink}
      className={`${className} ${stylesCard.card} ${styles.cardSearch} ${styles[variant]} relative flex lg:flex-col`}
    >
      <div
        className={`${stylesCard.cardImage} ${styles.cardImage} relative overflow-hidden self-start rounded-xl mr-3 lg:mr-0 lg:mb-5 bg-gray-600`}
      >
        {cardThumbnail && cardThumbnail.filename && (
          <Image
            src={cardThumbnail.filename}
            alt={cardThumbnail.alt || "missing alt"}
            fill
            sizes="(max-width: 768px) 80vw, (max-width: 1024px) 50vw, 25vw"
            className="object-cover"
          />
        )}
      </div>
      <div className={`${styles.cardContent} flex-1 text-white`}>
        <Text as="h6" tag="p" className="mb-1 pr-4 lg:mb-2 lg:pr-0 lg:w-3/4">
          {cardName}
        </Text>
        <Text as="body2" className={`line-clamp-2 pr-4 lg:pr-0 lg:w-11/12`}>
          {cardDescription}
        </Text>
        {cardLink && (
          <ArrowLink
            className="mt-4 text-buttonColor-primary"
          // link={content.link}
          >
            {t("actions.explore")}
          </ArrowLink>
        )}
      </div>
    </CustomLink>
  );
}
