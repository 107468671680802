import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import Head from "next/head";
import { useRouter } from "next/router";
import { useState, useEffect } from "react";
import dynamic from "next/dynamic";
import * as styles from "./CalendarWeekPage.module.scss";
import * as stylesHero from "@/common/components/Hero/Hero.module.scss";
import Hero from "@/common/components/Hero";
import InfoBar from "@/common/components/Story/InfoBar";
import CalendarWeekBlock from "../CalendarWeekBlock";
import PushBlock from "../PushBlock";
import AgendaBlock from "../AgendaBlock";
import FaqBlock from "@/common/components/Story/FaqBlock";
import { useEvents } from "@/hooks/useEvents";
import {
  formatDate,
  formatDateToUrl,
  addDays,
  addWeek,
  subtractWeek,
  getNearestSaturday,
  upperCaseFirstLetter,
} from "@/utils/format";
import useTranslation from "next-translate/useTranslation";
import { getLangValue } from "@/utils/global";

const DynamicFollowBlock = dynamic(
  () => import("@/common/components/FollowBlock"),
  {
    loading: () => <p>Loading...</p>,
  }
);

export default function CalendarWeekPage({
  blok,
  nestedData,
  apidaeData,
  settings,
  // calendarEvents,
}) {
  const { t } = useTranslation(["calendar", "common"]);

  let title =
    blok.hero && blok.hero[1]
      ? blok.hero[1]?.title + ` - ${t("common:courchevel")}`
      : blok.hero && blok.hero[0]
      ? blok.hero[0].title + ` - ${t("common:courchevel")}`
      : t("common:courchevel");
  let description =
    blok.hero && blok.hero[1]
      ? blok.hero[1]?.title + ` - ${t("common:courchevel")}`
      : blok.hero && blok.hero[0]
      ? blok.hero[0].title + ` - ${t("common:courchevel")}`
      : t("common:courchevel");
  let robots = "";
  if (blok?.meta_title) {
    title = blok.meta_title;
  }
  if (blok?.meta_description) {
    description = blok.meta_description;
  }
  if (blok?.hide_from_search) {
    robots = blok.hide_from_search ? "noindex, nofollow" : "index, follow";
  }
  const router = useRouter();
  const { locale } = useRouter();
  const { slug } = router.query;
  const { start, end } = router.query;
  const calendarEvents = useEvents(start, end);

  const [touristicContent, setTouristicContent] = useState(
    calendarEvents?.objetsTouristiques
  );
  const [currentDate, setCurrentDate] = useState(
    start ? new Date(start) : new Date()
  );

  useEffect(() => {
    setCurrentDate(
      start
        ? getNearestSaturday(new Date(start))
        : getNearestSaturday(new Date())
    );

    /*console.log(
      "date change ---------------------------------------------------------------"
    );
    console.log(
      "currentDateChange",
      currentDate,
      getNearestSaturday(currentDate)
    );*/
  }, [start, end]);

  useEffect(() => {
    setTouristicContent(calendarEvents?.objetsTouristiques);
  }, [calendarEvents, touristicContent]);

  const items = touristicContent?.map((item) => {
    const sortedPeriods = item.ouverture.periodesOuvertures.sort(
      (a, b) =>
        Math.abs(new Date(a.dateDebut) - currentDate) -
        Math.abs(new Date(b.dateDebut) - currentDate)
    );

    const closestPeriod = sortedPeriods[0];

    return {
      id: item.id,
      slug: "cultures-et-spectacles",
      tag:
        item.type === "FETE_ET_MANIFESTATION"
          ? item.informationsFeteEtManifestation &&
            item.informationsFeteEtManifestation?.typesManifestation
            ? item.informationsFeteEtManifestation.typesManifestation[0]
                .libelleFr
            : ""
          : item.informationsActivite.activitesCulturelles
          ? item.informationsActivite.activitesCulturelles[0].libelleFr
          : "",
      image: item?.illustrations
        ? item?.illustrations[0]?.traductionFichiers[0]?.url
        : "",
      title: getLangValue(item?.nom, locale),
      location: item?.localisation.adresse.commune.nom,
      startDate: closestPeriod.dateDebut,
      endDate: closestPeriod.dateFin,
      horaireOuverture: closestPeriod.horaireOuverture,
    };
  });

  return (
    <div className={styles.calendarWeekPage} {...storyblokEditable(blok)}>
      <Head>
        <title>{title}</title>
        <meta name="description" content={description} />
        {robots && <meta name="robots" content={robots} />}
        <link
          rel="canonical"
          href={`http://develop--courchevel.netlify.app/${router.asPath}`}
        />
      </Head>
      <Hero
        title={`${t("calendar:weekly-agenda")} ${formatDate(
          getNearestSaturday(currentDate),
          router.locale
        )} — ${formatDate(
          addDays(getNearestSaturday(currentDate), 7),
          router.locale
        )}`}
        content={blok.hero}
        settings={settings}
        {...storyblokEditable(blok.hero)}
      />
      <div className={stylesHero.heroPlaceholder}></div>
      {settings.content.infobar && settings.content.infobar[0] && (
        <InfoBar blok={settings.content.infobar[0]} />
      )}
      <div
        className="content-blocks relative bg-white z-10"
        {...storyblokEditable(blok.content)}
      >
        {blok.content &&
          blok.content.map((nestedBlok) => (
            <StoryblokComponent
              blok={nestedBlok}
              key={nestedBlok._uid}
              nestedData={nestedData}
            />
          ))}
      </div>
      <div className="content-blocks relative bg-white z-10 pt-16">
        <CalendarWeekBlock
          blok={{
            content: {
              items,
            },
          }}
        />
        <PushBlock data={settings?.content} />

        <DynamicFollowBlock />
        <FaqBlock
          blok={{ body: [{ _uid: 0 }, { _uid: 1 }, { _uid: 2 }, { _uid: 3 }] }}
        />
      </div>
    </div>
  );
}
