import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import Head from "next/head";
import dynamic from "next/dynamic";
import * as styles from "./CalendarPage.module.scss";
import * as stylesHero from "@/common/components/Hero/Hero.module.scss";
import Hero from "@/common/components/Hero";
import InfoBar from "@/common/components/Story/InfoBar";
import CalendarBlock from "../CalendarBlock";
import * as stylesStory from "@/common/components/Story/Story.module.scss";
import PushBlock from "../PushBlock";
import AgendaBlock from "../AgendaBlock";
import FaqBlock from "@/common/components/Story/FaqBlock";
import { useEvents, PER_PAGE } from "@/hooks/useEvents";
import { upperCaseFirstLetter } from "@/utils/format";
import { getLangValue } from "@/utils/global";
import useTranslation from "next-translate/useTranslation";

const DynamicFollowBlock = dynamic(
  () => import("@/common/components/FollowBlock"),
  {
    loading: () => <p>Loading...</p>,
  }
);

export default function CalendarPage({
  blok,
  nestedData,
  dataCateg,
  settings,
}) {
  const { t } = useTranslation("common");
  let title =
    blok.hero && blok.hero[1]
      ? blok.hero[1]?.title + ` - ${t("common:courchevel")}`
      : blok.hero && blok.hero[0]
      ? blok.hero[0].title + ` - ${t("common:courchevel")}`
      : t("common:courchevel");
  let description =
    blok.hero && blok.hero[1]
      ? blok.hero[1]?.title + ` - ${t("common:courchevel")}`
      : blok.hero && blok.hero[0]
      ? blok.hero[0].title + ` - ${t("common:courchevel")}`
      : t("common:courchevel");

  let robots = "";
  if (blok?.meta_title) {
    title = blok.meta_title;
  }
  if (blok?.meta_description) {
    description = blok.meta_description;
  }
  if (blok?.hide_from_search) {
    robots = blok.hide_from_search ? "noindex, nofollow" : "index, follow";
  }
  //console.log("settings", settings);
  //console.log("dataCateg", dataCateg);
  const router = useRouter();
  const { slug } = router.query;
  const { locale } = useRouter();
  const { start, end } = router.query;
  const path = router.asPath.split("?")[0];
  const segments = path.split("/");
  const lastSegment = segments[segments.length - 1];

  const categInfo = dataCateg?.find((categ) => categ.slug === lastSegment);
  const critereArray = categInfo?.criteres.map(
    (critere) => `${critere.type}:${critere.type_key}_${critere.id}`
  );
  const critereString = critereArray?.join("+");
  const critereType = categInfo?.criteres[0]?.type;
  // console.log("criterestring", critereString);
  const calendarEvents = useEvents(
    start,
    end,
    critereType,
    critereString,
    locale
  );

  const [touristicContent, setTouristicContent] = useState(
    calendarEvents?.objetsTouristiques
  );

  console.log("calendarStory", blok);

  const currentDate = start ? new Date(start) : new Date();

  const introduction =
    blok.content &&
    blok.content.find((item) => item.component === "introduction");

  const contentClone = blok.content && [...blok.content];
  const contentWithoutIntroduction =
    contentClone &&
    contentClone.filter((item) => item.component !== "introduction");

  useEffect(() => {
    setTouristicContent(calendarEvents?.objetsTouristiques);
    // console.log("updated content", touristicContent);
  }, [calendarEvents]);

  const langKeyTrad = `libelle${upperCaseFirstLetter(locale)}`;

  const items = touristicContent?.map((item) => {
    const sortedPeriods = item.ouverture.periodesOuvertures.sort(
      (a, b) =>
        Math.abs(new Date(a.dateDebut) - currentDate) -
        Math.abs(new Date(b.dateDebut) - currentDate)
    );

    const closestPeriod = sortedPeriods[0];

    return {
      id: item.id,
      slug: "cultures-et-spectacles",
      tag:
        item.type === "FETE_ET_MANIFESTATION"
          ? item.informationsFeteEtManifestation
            ? item.informationsFeteEtManifestation?.typesManifestation
            : null
            ? item.informationsFeteEtManifestation.typesManifestation[0]
                .libelleFr
            : ""
          : item?.informationsActivite?.activitesCulturelles
          ? item?.informationsActivite?.activitesCulturelles[0]?.libelleFr
          : "",
      image: item?.illustrations
        ? item?.illustrations[0]?.traductionFichiers[0]?.url
        : "",
      title: getLangValue(item?.nom, langKeyTrad),
      location: item?.localisation.adresse.commune.nom,
      startDate: closestPeriod.dateDebut,
      endDate: closestPeriod.dateFin,
      horaireOuverture: closestPeriod.horaireOuverture,
      ouverture: item?.ouverture,
    };
  });

  return (
    <div className={styles.calendarPage} {...storyblokEditable(blok)}>
      <Head>
        <title>{title}</title>
        <meta name="description" content={description} />
        {robots && <meta name="robots" content={robots} />}
        <link rel="canonical" href={`https://courchevel.com${router.asPath}`} />
      </Head>
      <Hero
        content={blok.hero}
        {...storyblokEditable(blok.hero)}
        settings={settings}
      />
      <div className={stylesHero.heroPlaceholder}></div>
      {settings.content.infobar && settings.content.infobar[0] && (
        <InfoBar blok={settings.content.infobar[0]} />
      )}
      <div
        className="content-blocks relative bg-white z-10"
        {...storyblokEditable(blok.content)}
      >
        {/* <div className={`${stylesStory.column} column`}>
          <div className="container flex flex-col lg:flex-row lg:gap-x-3"> */}
        {introduction && !introduction.is_hidden && (
          <StoryblokComponent blok={introduction} key={introduction._uid} />
        )}
        {/* </div>
        </div> */}
      </div>
      <div className="content-blocks relative bg-white z-10 pt-16">
        <CalendarBlock
          blok={{
            numFound: calendarEvents?.numFound,
            content: {
              items,
            },
          }}
          categories={dataCateg || []}
          title={blok.hero[0].title}
        />
        <PushBlock data={settings?.content} />
        {contentWithoutIntroduction &&
          contentWithoutIntroduction.map((nestedBlok) => (
            <StoryblokComponent
              blok={nestedBlok}
              key={nestedBlok._uid}
              nestedData={nestedData}
            />
          ))}
        <DynamicFollowBlock />
        <FaqBlock
          blok={{ body: [{ _uid: 0 }, { _uid: 1 }, { _uid: 2 }, { _uid: 3 }] }}
        />
      </div>
    </div>
  );
}
