import React, { createRef, useEffect, useRef, useState } from "react";
import Link from "next/link";
import Image from "next/image";
import { useRouter } from "next/router";
import dynamic from "next/dynamic";
import useTranslation from "next-translate/useTranslation";
import { storyblokEditable, StoryblokComponent } from "@storyblok/react";
import * as styles from "./Navigation.module.scss";
import LogoPaysage from "@/public/logo-courchevel-paysage.svg";
import LogoSimple from "@/public/logo-courchevel-simple.svg";
import backgroundImage from "@/public/menu-bg.png";
import Button from "../Core/Button";
import Text from "../Core/Text";
import CardSearch from "../Card/Search";
// import { ReactLenis, useLenis } from "@studio-freight/react-lenis";
import { CSSTransition } from "react-transition-group";
import { disableBodyScroll, enableBodyScroll } from "@/utils/scroll";
import Icon from "@/utils/Icon";
import { useDrawers } from "../DrawerStack";
import LangSwitcher from "../LangSwitcher";
import ArrowLink from "../Core/ArrowLink";

import debounceFn from "debounce-fn";
import HeaderMenu from "../Story/HeaderMenu";
import Cta from "../Story/Cta";
import CustomLink from "../CustomLink";
import search from "@/pages/api/storyblok/search";
const DynamicDrawerMeteo = dynamic(() => import("../Modals/DrawerMeteo"), {
  ssr: false,
});
const DynamicDrawerWebcam = dynamic(() => import("../Modals/DrawerWebcam"), {
  ssr: false,
});
const DynamicDrawerTrail = dynamic(() => import("../Modals/DrawerTrail"), {
  ssr: false,
});

const Navigation = ({ blok, locales, locale, mode = "floating" }) => {
  // const lenisContext = useLenis();

  const [openMenu, setOpenMenu] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [menuMinified, setMenuMinified] = useState(
    mode === "floating" ? false : true
  );
  const [currentRoute, setCurrentRoute] = useState('');

  const [searchResults, setSearchResults] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [numFound, setNumFound] = useState(0);
  // const [numDisplayed, setNumDisplayed] = useState(0);
  const [sbDisplayed, setSbDisplayed] = useState(0);
  const [apidaeDisplayed, setApidaeDisplayed] = useState(0);

  const [menuScrolled, setMenuScrolled] = useState(false);
  const [openedMenuItem, setOpenedMenuItem] = useState(null);
  const [submenuOpened, setSubmenuOpened] = useState(null);
  const [lastScrollY, setLastScrollY] = useState(false);

  const [history, setHistory] = useState([]);

  const navSearchRef = useRef(null);
  const refHeader = useRef(null);

  const searchInputDesktopRef = useRef(null);
  const searchInputMobileRef = useRef(null);

  let isMobile = false;
  if (typeof window !== "undefined") {
    isMobile = window.innerWidth <= 1024;
  }

  const { openDrawer } = useDrawers();
  const { t } = useTranslation("common");
  const router = useRouter();

  // Mobile only
  const openMenuItem = (itemIndex) => {
    if (openedMenuItem === itemIndex) {
      setOpenedMenuItem(null);
    } else {
      setOpenedMenuItem(itemIndex);
    }
  };

  // Desktop only
  // const openSubmenu = (itemIndex) => {
  //   if (submenuOpened === itemIndex) {
  //     setSubmenuOpened(null);
  //   } else {
  //     setSubmenuOpened(itemIndex);
  //   }
  // };

  const openSearchPanel = () => {
    if (openSearch) {
      setOpenSearch(false);
      if (searchResults.length > 0) {
        setSearchResults([]);
        setSearchQuery("");
      }
      enableBodyScroll(document);
      searchInputDesktopRef.current.value = "";
      searchInputMobileRef.current.value = "";
      // if (typeof requestAnimationFrame !== "undefined") {
      //   lenisContext?.start();
      // }
    } else {
      setOpenSearch(true);
      // if (typeof requestAnimationFrame !== "undefined") {
      //   lenisContext?.stop();
      // }
      disableBodyScroll(document);
      // Focus input ref
      setTimeout(() => {
        if (isMobile) {
          searchInputMobileRef.current?.focus();
        } else {
          searchInputDesktopRef.current?.focus();
        }
      }, 100);
    }
  };

  const onClickOverlay = () => {
    // if (requestAnimationFrame !== "undefined") {
    //   lenisContext?.start();
    // }
    if (searchResults.length > 0) {
      setSearchResults([]);
      setSearchQuery("");
    }
    enableBodyScroll(document);
    searchInputDesktopRef.current.value = "";
    searchInputMobileRef.current.value = "";
    setOpenMenu(false);
    setOpenSearch(false);
  };

  const onMobileOpenMenu = () => {
    disableBodyScroll(document, lastScrollY);
    setOpenMenu(true);
    setCurrentRoute(router.pathname);
  };

  const onMobileCloseMenu = () => {
    // Define a function to handle the route change
    const handleRouteChange = () => {
      enableBodyScroll(document);
      //setOpenMenu(false);
      router.events.off('routeChangeComplete', handleRouteChange);
    };

    // Check if the route is the same
    if (router.pathname === currentRoute) {
      enableBodyScroll(document);
      setOpenMenu(false);
    } else {
      // Listen for route change completion
      router.events.on('routeChangeComplete', handleRouteChange);
    }
  };

  const handleMouseEnter = (e) => {
    const target = e.target;
    const itemIndex = target ? target.getAttribute("data-index") : null;

    if (itemIndex !== null) {
      const section = target.nextSibling;

      if (section && section.className.includes("nav-submenu")) {
        setSubmenuOpened(parseInt(itemIndex));
      } else {
        setSubmenuOpened(null);
      }
    } else if (submenuOpened !== null) {
      setSubmenuOpened(null);
    }
  };

  // const onSetSubmenuOpened = (itemIndex) => {
  //   setSubmenuOpened(itemIndex);
  // };

  const onMouseLeaveHeader = (e) => {
    setSubmenuOpened(null);
  };

  const onMouseEnterOverlay = (e) => {
    setSubmenuOpened(null);
  };

  useEffect(() => {
    function handleKeyDown(event) {
      if (event.keyCode === 27) {
        setOpenSearch(false);
        // if (typeof requestAnimationFrame !== "undefined") {
        //   lenisContext?.start();
        // }
        enableBodyScroll(document);
        if (searchResults.length > 0) {
          setSearchResults([]);
          setSearchQuery("");
        }
      }
    }

    function handleResize(event) {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    }

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("resize", handleResize);

    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    let savedRefMobileMenu = null;

    const handleScroll = () => {
      const wrapper = document.querySelector(".js-wrapper");

      setLastScrollY(window.scrollY);
      if (mode === "floating") {
        if (window.scrollY > 10 && !menuMinified) {
          setMenuMinified(true);
        } else if (
          window.scrollY < 10 &&
          menuMinified &&
          !wrapper?.classList?.contains("is-fixed")
        ) {
          setMenuMinified(false);
        }
      } else {
        if (window.scrollY > 10 && !menuScrolled) {
          setMenuScrolled(true);
        } else if (window.scrollY < 10 && menuScrolled) {
          setMenuScrolled(false);
        }
      }
    };

    /* if (refHeader.current) {
      let scrollTarget = savedRefMobileMenu;
      if (!scrollTarget) {
        scrollTarget = refHeader.current.querySelector("#mobile-nav-menu");
      }

      if (scrollTarget) {
        savedRefMobileMenu = scrollTarget;
        if (openMenu) {
          disableBodyScroll(document, lastScrollY);
        } else {
          enableBodyScroll(document);
          savedRefMobileMenu = null;
        }
      }
    } */

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [openMenu, menuMinified, mode, menuScrolled]);

  useEffect(() => {
    setOpenSearch(false);
    // if (typeof requestAnimationFrame !== "undefined") {
    //   lenisContext?.start();
    // }
    // window.localStorage.setItem("previousPath", history);
    // setHistory([...history, router.asPath]);
    enableBodyScroll(document);
  }, [router]);

  // console.log("History", history);

  const onOpenDrawerMeteo = () => {
    const linkDrawerMeteo = blok.weather_link && blok.weather_link[0].link;

    // window.history.pushState(
    //   {},
    //   "",
    //   "/" + linkDrawerMeteo?.story?.full_slug || ""
    // );
    openDrawer(
      DynamicDrawerMeteo,
      {
        title: t("weather.drawer.title"),
        drawerKey: "meteo",
        link: linkDrawerMeteo,
      },
      { nodeRef: createRef(null), targetElement: "#drawer-meteo" }
    );
  };

  const onOpenDrawerWebcam = () => {
    const linkDrawerWebcam = blok.webcam_link && blok.webcam_link[0].link;

    // window.history.pushState(
    //   {},
    //   "",
    //   "/" + linkDrawerWebcam?.story?.full_slug || ""
    // );
    openDrawer(
      DynamicDrawerWebcam,
      {
        title: t("webcam.drawer.title"),
        drawerKey: "webcam",
        link: linkDrawerWebcam,
      },
      { nodeRef: createRef(null), targetElement: "#drawer-webcam" }
    );
    // router.replace("/webcam");
  };

  const onOpenDrawerTrail = () => {
    const linkDrawerTrail = blok.trail_link && blok.trail_link[0].link;

    // window.history.pushState(
    //   {},
    //   "",
    //   "/" + linkDrawerTrail?.story?.full_slug || ""
    // );
    openDrawer(
      DynamicDrawerTrail,
      {
        title: t("trail.drawer.title"),
        drawerKey: "trail",
        link: linkDrawerTrail,
      },
      { nodeRef: createRef(null), targetElement: "#drawer-trail" }
    );
  };

  const handleOnChangeSearch = async (e) => {
    const target = e.target;
    const value = target.value;

    if (value.length === 0) {
      setSearchResults([]);
      setSearchQuery("");
      return;
    }

    setSearchQuery(value);
    if (value.length > 2) {
      setIsLoading(true);

      const response = await fetch(
        `/api/storyblok/search?search=${value}&keywords=${value}&per_page=${4}&page=1&first=0&locale=${locale}`
      );
      const parsedResponse = await response.json();

      const sResults = [];
      let totalFound = 0;
      if (parsedResponse?.storyblok?.stories) {
        sResults.push(...parsedResponse?.storyblok?.stories);
        if (parsedResponse?.storyblok?.total) {
          totalFound += parsedResponse?.storyblok?.total;
        }
      }
      if (parsedResponse?.apidae?.objetsTouristiques) {
        sResults.push(...parsedResponse?.apidae?.objetsTouristiques);
        if (parsedResponse?.apidae?.numFound) {
          totalFound += parsedResponse?.apidae?.numFound;
        }
      }
      setSearchResults(sResults);

      setNumFound(totalFound);
      setSbDisplayed(2);
      setApidaeDisplayed(2);

      setIsLoading(false);
    }
  };

  const handleOnSeeMore = async () => {
    const searchValue = isMobile
      ? searchInputMobileRef.current?.value
      : searchInputDesktopRef.current?.value;
    if (searchValue.length > 2) {
      setIsLoading(true);

      const response = await fetch(
        `/api/storyblok/search?search=${searchValue}&keywords=${searchValue}&per_page=${4}&first=${apidaeDisplayed}&page=${
          1 + sbDisplayed / 2
        }&locale=${locale}`
      );
      const parsedResponse = await response.json();

      // if (parsedResponse?.total) {
      //   setNumFound(parsedResponse.total);
      // }
      const sResults = [];
      let totalFound = 0;
      if (parsedResponse?.storyblok?.stories) {
        sResults.push(...parsedResponse?.storyblok?.stories);
        setSbDisplayed(
          sbDisplayed + parsedResponse?.storyblok?.stories?.length
        );
      }
      if (parsedResponse?.apidae?.objetsTouristiques) {
        sResults.push(...parsedResponse?.apidae?.objetsTouristiques);
        setApidaeDisplayed(
          apidaeDisplayed + parsedResponse?.apidae?.objetsTouristiques?.length
        );
      }
      setSearchResults([...searchResults, ...sResults]);

      setIsLoading(false);
    }
  };

  const debouncedOnChangeSearch = debounceFn(handleOnChangeSearch, {
    wait: 500,
  });

  const sbEditableProps = blok ? storyblokEditable(blok) : {};

  const bookingLink = blok?.booking_link && blok?.booking_link[0];

  return (
    <header
      ref={refHeader}
      className={`fixed text-white border-b ${styles.header} ${
        menuMinified ? styles.headerMinified : ""
      } ${menuScrolled || mode === "map" ? styles.headerScrolled : ""} ${
        openMenu ? styles.headerOpenMenu : ""
      } ${mode !== "floating" ? styles.headerFixed : ""} ${
        submenuOpened !== null ? styles.headerSubmenuOpened : ""
      } ${openSearch ? styles.headerSearchOpened : ""} w-full top-0 z-20`}
      {...sbEditableProps}
      onMouseLeave={onMouseLeaveHeader}
    >
      <div
        className={`${styles.overlay} ${
          submenuOpened !== null || openSearch ? "visible" : "hidden"
        }`}
        onClick={onClickOverlay}
        onMouseEnter={onMouseEnterOverlay}
      ></div>
      {/* {!menuMinified && ( */}
      <div
        className={`${styles.topNav} top-nav hidden md:block text-sm ${
          menuMinified ? "bg-white border-b" : ""
        } `}
      >
        <div className="container ">
          <div className="flex justify-end items-center py-3 gap-x-6">
            {blok?.buy_link && blok?.buy_link[0] && (
              <CustomLink
                link={blok?.buy_link[0]?.link}
                className="md:hover:underline"
              >
                {blok?.buy_link[0]?.label}
              </CustomLink>
            )}
            {blok?.help_link && blok?.help_link[0] && (
              <CustomLink
                link={blok?.help_link[0]?.link}
                className="md:hover:underline"
              >
                {blok?.help_link[0]?.label}
              </CustomLink>
            )}
            <div>
              <LangSwitcher />
            </div>
          </div>
        </div>
      </div>
      {/* )} */}
      <div
        className={`${styles.mainNav} z-10 relative ${
          menuMinified ? "bg-white " : ""
        } `}
      >
        <div className="container  ">
          <div className="flex justify-between items-center lg:justify-start py-2 lg:py-0 lg:gap-x-3">
            <div className="flex justify-start rounded-lg bg-white self-stretch items-center pr-2 lg:pr-0 xl:pr-2">
              <Link href={`/${locale}/`} className="p-2 lg:px-4 xl:py-3">
                <span className="sr-only">Courchevel</span>
                {blok && blok.logo ? (
                  <>
                    <Image
                      src={blok.logo.filename}
                      alt={blok.logo.alt}
                      width={170}
                      height={48}
                      className={styles.mainNavLogo}
                    />
                    <LogoSimple className="hidden lg:inline-block xl:hidden scale-90" />
                  </>
                ) : (
                  <LogoPaysage />
                )}
              </Link>
            </div>
            <div className="flex items-center lg:hidden">

              <button
                type="button"
                className={`${
                  menuMinified ? "" : "bg-blur"
                } rounded-md p-4 h-14 w-14 inline-flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500`}
                aria-expanded="false"
                onClick={openSearchPanel}
              >
                <span className="sr-only">{t("actions.search")}</span>
                {openSearch ? (
                  <Icon icon="icon-cross-24" size={24} />
                ) : (
                  <Icon icon="icon-search-24" size={24} />
                )}
              </button>

              <button
                type="button"
                onClick={onMobileOpenMenu}
                className={`${styles.menuBurger} ${
                  menuMinified ? "" : "bg-blur"
                } ${
                  openSearch ? "hidden" : ""
                } rounded-md p-4 inline-flex items-center justify-center lg:hover:bg-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500`}
                aria-expanded="false"
              >
                <span className="sr-only">{t("actions.open-menu")}</span>
                {/* <!-- Heroicon name: outline/menu --> */}
                <Icon icon="icon-burger-24" size={24} />
              </button>
            </div>

            <div className="lg:hidden">
              <Button
                linktype={bookingLink?.link?.linktype}
                href={bookingLink?.link?.cached_url}
                link={bookingLink?.link}
                variant="squared"
                className="ml-4"
                style={{ position: 'fixed', right: '25px', bottom: '25px' }}
              >
                {bookingLink?.label || t("actions.book")}
              </Button>
            </div>

            <div className="hidden lg:flex items-stretch justify-end rounded-lg md:flex-1 lg:gap-x-3">
              <div
                className={`${
                  styles.centerPart
                } flex justify-between items-center flex-1 rounded-lg pl-8 lg:px-2 xl:pl-8 pr-5 xl:pr-5 ${
                  menuMinified ? "" : "bg-black/20"
                } ${
                  submenuOpened !== null || openSearch || menuMinified
                    ? ""
                    : "relative"
                }`}
              >
                <div className={styles.backdropBlur}></div>
                <nav
                  className={`${styles.nav} flex items-center h-full`}
                  role="menu"
                >
                  {blok &&
                    blok.menu.map((nestedBlok, index) =>
                      nestedBlok?.component === "header_menu" ? (
                        <HeaderMenu
                          className={
                            nestedBlok.component === "CTA"
                              ? `${styles.navMenuItem} nav-submenu font-medium px-3 md:hover:bg-buttonColor-primary md:hover:text-white duration-200`
                              : ""
                          }
                          blok={nestedBlok}
                          key={nestedBlok._uid}
                          dataindex={index}
                          currentSubmenuOpened={submenuOpened}
                          // onSetSubmenuOpened={onSetSubmenuOpened}
                          onMouseEnter={handleMouseEnter}
                          role="menuitem"
                        />
                      ) : nestedBlok?.component === "CTA" ? (
                        <Cta
                          className={
                            nestedBlok.component === "CTA"
                              ? `${styles.navMenuItem} nav-submenu font-medium px-3 md:hover:bg-buttonColor-primary md:hover:text-white duration-200`
                              : ""
                          }
                          blok={nestedBlok}
                          key={nestedBlok._uid}
                          dataindex={index}
                          currentSubmenuOpened={submenuOpened}
                          // onSetSubmenuOpened={onSetSubmenuOpened}
                          onMouseEnter={handleMouseEnter}
                          role="menuitem"
                        />
                      ) : (
                        <></>
                      )
                    )}
                </nav>

                <button
                  className={`search inline-flex items-center justify-center px-4 py-2 ${
                    openSearch ? "rounded-xl p-4 h-14 w-14" : "rounded-full"
                  } ${
                    menuMinified || submenuOpened !== null
                      ? "hover:bg-text-h1/10"
                      : "hover:bg-white/10"
                  }`}
                  role="button"
                  aria-label={t("actions.search")}
                  onClick={openSearchPanel}
                >
                  {openSearch ? (
                    <Icon icon="icon-cross-24" size={24} />
                  ) : (
                    <>
                      <Icon icon="icon-search-24" size={24} className="mr-2" />
                      <Text as="nav" className="hidden xl:inline-block">
                        {t("actions.search")}
                      </Text>
                    </>
                  )}
                </button>

                <CSSTransition
                  in={openSearch}
                  timeout={300}
                  classNames="appear-from-top"
                  nodeRef={navSearchRef}
                >
                  <div
                    ref={navSearchRef}
                    className={`${
                      styles.navSearch
                    } nav-search text-white hidden lg:block py-10 ${
                      openSearch ? "visible" : "invisible"
                    }`}
                    aria-label={t("actions.search")}
                  >
                    <div className="container">
                      <div
                        className={`flex flex-col ${
                          isLoading ? "cursor-wait" : ""
                        }`}
                      >
                        <div className="mt-6 lg:mt-2">
                          <input
                            type="text"
                            placeholder={t("actions.search")}
                            ref={searchInputDesktopRef}
                            onChange={debouncedOnChangeSearch}
                            className="w-full h-10 border-b border-white/10 pr-4 py-2 lg:py-4 bg-transparent text-xl lg:text-2xl focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                          />
                        </div>

                        <div
                          className={`${styles.navSearchResults} mt-8 lg:mt-5 lg:grid grid-cols-12 gap-x-3 gap-y-4`}
                        >
                          {searchResults?.length > 0 &&
                            searchResults.map((resultItem) => (
                              <CardSearch
                                key={resultItem.id}
                                data={resultItem}
                                className="mb-3 lg:mb-0 lg:col-span-3"
                              />
                            ))}

                          {searchResults?.length === 0 &&
                            searchQuery.length > 0 &&
                            !isLoading && (
                              <div className="col-span-12 py-4">
                                <Text as="ui2" className="text-white">
                                  {t("no-results")}
                                </Text>
                              </div>
                            )}
                        </div>

                        {numFound > 4 &&
                          sbDisplayed + apidaeDisplayed < numFound && (
                            <Button
                              variant="outline inverted squared"
                              className="mt-6 md:self-center"
                              onClick={handleOnSeeMore}
                              loading={isLoading}
                            >
                              {t("actions.see-results")}
                            </Button>
                          )}
                      </div>
                    </div>
                  </div>
                </CSSTransition>
              </div>

              <div
                className={`${
                  styles.rightPart
                } hidden lg:flex px-3 py-2 items-center rounded-lg ${
                  menuMinified ? "" : "bg-blur"
                }`}
              >
                <CustomLink
                  link={blok?.webcam_link && blok.webcam_link[0]?.link}
                  className={`p-4 lg:p-0 xl:p-4 h-14 w-14 mr-1 inline-flex items-center justify-center rounded-lg focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 ${
                    menuMinified || submenuOpened !== null
                      ? "hover:bg-text-h1/10"
                      : "hover:bg-white/10"
                  }`}
                  aria-expanded="false"
                  aria-label="Webcams"
                  title="Webcams"
                >
                  <Icon icon="icon-webcam-24" size={24} />
                </CustomLink>
                <CustomLink
                  link={blok?.trail_link && blok.trail_link[0]?.link}
                  className={`p-4 lg:p-0 xl:p-4 h-14 w-14 mr-1 inline-flex items-center justify-center rounded-lg focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 ${
                    menuMinified || submenuOpened !== null
                      ? "hover:bg-text-h1/10"
                      : "hover:bg-white/10"
                  }`}
                  aria-expanded="false"
                  aria-label="Pistes et remontées"
                  title="Pistes et remontées"
                >
                  <Icon icon="icon-piste-24" size={24} />
                </CustomLink>
                <CustomLink
                  link={blok?.weather_link && blok.weather_link[0]?.link}
                  className={`p-4 lg:p-0 xl:p-4 h-14 w-14 inline-flex items-center justify-center rounded-lg focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 ${
                    menuMinified || submenuOpened !== null
                      ? "hover:bg-text-h1/10"
                      : "hover:bg-white/10"
                  }`}
                  aria-expanded="false"
                  aria-label="Météo"
                  title="Météo"
                >
                  <Icon icon="icon-meteo-24" size={24} />
                </CustomLink>

                <Button
                  linktype={bookingLink?.link?.linktype}
                  href={bookingLink?.link?.cached_url}
                  link={bookingLink?.link}
                  variant="squared"
                  className="ml-4"
                >
                  {bookingLink?.label || t("actions.book")}
                </Button>
              </div>
            </div>
          </div>
        </div>

        {/* <!--
        Mobile menu, show/hide based on mobile menu state.
      --> */}
        {openMenu && (
          <div
            id="mobile-nav-menu"
            className={`${styles.mobileNavMenu} fixed bottom-0 top-0 inset-x-0 transition transform origin-top-right lg:hidden`}
          >
            <div className={`${styles.menuMobile} h-full overflow-auto`}>
              <Image
                src={backgroundImage}
                alt="Blue background"
                className={`${styles.menuMobileBackground} w-full h-full`}
              />
              <div className="container relative z-10 flex flex-col min-h-full">
                <div className="flex items-center justify-between pt-2	">
                  <div>
                    <LogoPaysage />
                  </div>
                  <div className="flex gap-4">
                    <LangSwitcher />

                    <button
                      type="button"
                      onClick={onMobileCloseMenu}
                      className="bg-blur rounded-md p-4 h-14 w-14 inline-flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                    >
                      <span className="sr-only">{t("actions.close-menu")}</span>
                      <Icon icon="icon-cross-24" size={24} />
                    </button>
                  </div>
                </div>
                <div className="mt-6">
                  <nav className={`${styles.menuMobileNav}`}>
                    {blok &&
                      blok.menu.map((nestedBlok, index) => {
                        const isHeaderMenu =
                          nestedBlok.component === "header_menu";

                        if (isHeaderMenu) {
                          return (
                            <React.Fragment
                              role="presentation"
                              key={`rfnav${nestedBlok._uid}-${index}`}
                            >
                              <button
                                onClick={() => openMenuItem(index)}
                                role="menuitem"
                                aria-haspopup={
                                  nestedBlok.col && nestedBlok.col.length > 0
                                    ? "true"
                                    : "false"
                                }
                                aria-expanded={openedMenuItem == index}
                                className="flex items-center justify-between w-full border-b border-white/10 py-5"
                                key={nestedBlok._uid}
                                dataindex={index}
                              >
                                <Text as="nav" className="">
                                  {nestedBlok.label || nestedBlok.title}
                                </Text>
                                {nestedBlok.col &&
                                  nestedBlok.col.length > 0 && (
                                    <Icon icon="icon-arrow-down-16" size={16} />
                                  )}
                              </button>
                              {nestedBlok.col && nestedBlok.col.length > 0 && (
                                <ul
                                  key={`nb${nestedBlok._uid}`}
                                  role="menu"
                                  aria-label={
                                    nestedBlok.label || nestedBlok.title
                                  }
                                  className="text-lg overflow-hidden"
                                >
                                  {nestedBlok.col &&
                                    nestedBlok.col.length > 0 &&
                                    nestedBlok.col.map(
                                      (colBlok, colBlokIndex) => {
                                        if (
                                          colBlok &&
                                          colBlok.component === "link_list"
                                        ) {
                                          return (
                                            <>
                                              <li
                                                key={colBlokIndex}
                                                role="none"
                                                className={`${
                                                  colBlokIndex > 0 ? "mt-8" : ""
                                                } mb-3`}
                                              >
                                                <Text
                                                  as="ui2"
                                                  className="uppercase text-button-h3"
                                                >
                                                  {colBlok.title}
                                                </Text>
                                              </li>
                                              {colBlok &&
                                                colBlok.list &&
                                                colBlok.list.map(
                                                  (
                                                    colBlokListItem,
                                                    colBlokListItemIndex
                                                  ) => (
                                                    <li
                                                      key={colBlokListItem._uid}
                                                      onClick={() =>
                                                        onMobileCloseMenu()
                                                      }
                                                      role="none"
                                                      className={`${
                                                        colBlokIndex <
                                                          nestedBlok.col
                                                            .length &&
                                                        colBlokListItemIndex <
                                                          colBlok.list.length -
                                                            1
                                                          ? "mb-1"
                                                          : "mb-8"
                                                      }`}
                                                    >
                                                      {colBlokListItem.is_arrow_link ? (
                                                        <ArrowLink
                                                          link={
                                                            colBlokListItem.link
                                                          }
                                                          className=""
                                                        >
                                                          {
                                                            colBlokListItem.label
                                                          }
                                                        </ArrowLink>
                                                      ) : (
                                                        <CustomLink
                                                          link={
                                                            colBlokListItem.link
                                                          }
                                                        >
                                                          {
                                                            colBlokListItem.label
                                                          }
                                                        </CustomLink>
                                                      )}
                                                    </li>
                                                  )
                                                )}
                                            </>
                                          );
                                        }
                                      }
                                    )}
                                </ul>
                              )}
                            </React.Fragment>
                          );
                        }
                        return (
                          <CustomLink
                            link={
                              nestedBlok.link ? nestedBlok.link.cached_url : "/"
                            }
                            role="menuitem"
                            className="flex items-center justify-between w-full border-b border-white/10 py-5"
                            key={nestedBlok._uid}
                            dataindex={index}
                            onClick={() => onMobileCloseMenu()}
                          >
                            <Text as="nav" className="">
                              {nestedBlok.label || nestedBlok.title}
                            </Text>
                          </CustomLink>
                        );
                      })}
                  </nav>
                </div>

                <div
                  className={`${styles.menuMobileBottomNav} flex px-3 py-2 items-center justify-between rounded-lg bg-blur mb-4`}
                >
                  <CustomLink
                    link={blok.webcam_link && blok.webcam_link[0].link}
                    className={`p-4 flex flex-col items-center justify-center hover:bg-white/10 rounded-lg mr-1 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500`}
                    aria-expanded="false"
                    onClick={() => onMobileCloseMenu()}
                  >
                    <Icon icon="icon-webcam-24" size={24} />
                    <Text as="ui3" className="mt-1">
                      {t("webcam.mobile-menu")}
                    </Text>
                  </CustomLink>
                  <CustomLink
                    link={blok.trail_link && blok.trail_link[0].link}
                    className={`p-4 flex flex-col items-center justify-center hover:bg-white/10 rounded-lg mr-1 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500`}
                    aria-expanded="false"
                    onClick={() => onMobileCloseMenu()}
                  >
                    <Icon icon="icon-piste-24" size={24} />
                    <Text as="ui3" className="mt-1">
                      {t("trail.mobile-menu")}
                    </Text>
                  </CustomLink>
                  <CustomLink
                    link={blok.weather_link && blok.weather_link[0].link}
                    className={`p-4 flex flex-col items-center justify-center hover:bg-white/10 rounded-lg focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500`}
                    aria-expanded="false"
                    onClick={() => onMobileCloseMenu()}
                  >
                    <Icon icon="icon-meteo-24" size={24} />
                    <Text as="ui3" className="mt-1">
                      {t("weather.mobile-menu")}
                    </Text>
                  </CustomLink>
                </div>
                <Button
                  linktype={bookingLink?.link?.linktype}
                  href={bookingLink?.link?.cached_url}
                  variant="squared"
                  link={bookingLink?.link}
                  className="w-full mb-6 text-center"
                >
                  {t("actions.book")}
                </Button>
              </div>
            </div>
          </div>
        )}

        {openSearch && (
          <div
            className={`absolute top-0 inset-x-0 transition transform origin-top-right z-10 lg:hidden`}
          >
            <div
              className={`${styles.searchPanel} h-screen lg:h-auto pb-8 lg:pb-14`}
            >
              <div className="container  z-10 flex flex-col min-h-full">
                <div className="flex items-center justify-between pt-2">
                  <div></div>
                  <Text as="ui2" className="uppercase text-white">
                    {t("actions.search")}
                  </Text>
                  <div className="">
                    <button
                      type="button"
                      onClick={openSearchPanel}
                      className="bg-blur rounded-md p-4 h-14 w-14 inline-flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                    >
                      <span className="sr-only">
                        {t("actions.close-search-panel")}
                      </span>
                      <Icon
                        icon="icon-cross-24"
                        className="text-white"
                        size={24}
                      />
                    </button>
                  </div>
                </div>
                <div className="mt-6">
                  <input
                    type="text"
                    placeholder={t("actions.search")}
                    ref={searchInputMobileRef}
                    onChange={debouncedOnChangeSearch}
                    className="text-white w-full h-10 border-b border-white/10 pr-4 py-2 lg:py-4 bg-transparent text-xl lg:text-2xl focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                  />
                </div>

                <div className="mt-8 lg:mt-5 lg:grid grid-cols-12 gap-x-3">
                  {searchResults?.length > 0 &&
                    searchResults.map((resultItem) => (
                      <CardSearch
                        key={resultItem.id}
                        data={resultItem}
                        className="mb-3 lg:mb-0 lg:col-span-3"
                      />
                    ))}
                  {searchResults?.length === 0 &&
                    searchQuery.length > 0 &&
                    !isLoading && (
                      <div className="col-span-12 py-4">
                        <Text as="ui2" className="text-white">
                          {t("no-results")}
                        </Text>
                      </div>
                    )}
                </div>

                {numFound > 4 && sbDisplayed + apidaeDisplayed < numFound && (
                  <Button
                    variant="outline inverted squared"
                    className="mt-6 md:self-center"
                    onClick={handleOnSeeMore}
                  >
                    {t("actions.see-results")}
                  </Button>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </header>
  );
};

export default Navigation;
